import React from 'react';
import { bool } from 'prop-types';

import RangeFilterPlain from './RangeFilterPlain';
import RangeFilterPopup from './RangeFilterPopup';

const RangeFilter = props => {
  const { showAsPopup, marketplaceCurrency, ...rest } = props;
  return showAsPopup ? (
    <RangeFilterPopup marketplaceCurrency={marketplaceCurrency} {...rest} />
  ) : (
    <RangeFilterPlain marketplaceCurrency={marketplaceCurrency} {...rest} />
  );
};

RangeFilter.defaultProps = {
  showAsPopup: false,
};

RangeFilter.propTypes = {
  showAsPopup: bool,
};

export default RangeFilter;
